<template>
  <div class="col-6 col-lg-4">
    <router-link :to="partnerId ? { name: 'shops', query: { categoryId } } : '#'">
      <div
        class="home-categorie-item"
        :style="style"
        @click="onClick"
      >
        <h3>{{ nom }}</h3>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'HomeCategorieItem',
  props: {
    categoryId: {
      type: String
    },
    nom: {
      type: String
    },
    image: {
      type: String
    }
  },
  computed: {
    partnerId() {
      return this.$route.params.partnerId
    },

    style() {
      if (!this.image) {
        return {}
      }
      return {
        backgroundImage: `url('${this.$resize(this.image, { w: 392, h: 312, f: 'outside' })}')`
      }
    }
  },
  methods: {
    onClick(ev) {
      if (this.partnerId) {
        return
      }
      ev.stopPropagation()
      ev.preventDefault()
      this.$emit('click', { categoryId: this.categoryId })
    }
  }
}
</script>
<!--  backgroundImage: `url(${require('@/assets/categorie-boucherie.png')})` -->

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.home-categorie-item {
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 25% 50%;
}
h3 {
  color: white;
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(md) {
    font-size: 25px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 30px;
    min-height: 75px;
  }
}
a {
  text-decoration: none;
}
</style>
