<template>
  <div
    id="home-categories"
    class="container-lg p-0"
  >
    <div class="title">
      <h2>{{ title || $t('categories.title') }}</h2>
      <div>
        <img
          class="d-lg-none"
          src="@/assets/down-arrow-white.svg"
        >
      </div>
    </div>
    <div class="home-categories-liste row g-3 g-md-5 m-2 m-lg-0">
      <template v-for="category in categories">
        <CategorieItem
          :key="category.code"
          :category-id="category.code"
          :nom="category.getTitle($l)"
          :image="category.image && category.image.url"
          @click="ev => $emit('select', ev)"
        />
      </template>
    </div>

    <div class="home-categorie-bg d-none d-lg-flex" />
  </div>
</template>

<script>
import CategorieItem from './CategorieItem.vue'

export default {
  name: 'Categories',
  components: {
    CategorieItem
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    partnerId: {
      type: String
    }
  },
  data: () => ({
    loading: false,
    categories: []
  }),
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      return this.$store.dispatch('partner/categories', { id: this.partnerId, lang: this.$l })
        .then(categories => {
          this.categories = categories
          this.$emit('loaded', categories)
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#home-categories {
  position: relative;
  @include media-breakpoint-up(lg) {
    margin: 40px auto;
  }
}

.title {
  background-color: var(--green-color);
  padding: 30px 0;

  @include media-breakpoint-up(lg) {
    background-color: transparent;
  }

  h2 {
    z-index: 2;
    display: inline-block;
    color: white;
    padding-bottom: 20px;
    position: relative;
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    max-width: 66%;

    @include media-breakpoint-up(lg) {
      max-width: 70%;
      font-size: 40px;
      background-color: white;
      color: var(--gray-color);
      padding-bottom: 0;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.home-categories-liste {
  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  //grid-template-rows: auto;
  //gap: 40px 40px;
  position: relative;
  z-index: 2;
}
.home-categorie-bg {
  position: absolute;
  top: 47px;
  left: 5%;
  right: 5%;
  bottom: 128px;
  border: 15px solid var(--green-color);
  z-index: 1;
}
</style>
